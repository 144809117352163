import * as React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import Seo from "../components/seo"
import { useEffect } from "react"

const IndexPage = () => {

  useEffect(
    () => {
      window.location = "/qm";
    },
    []
  );

  return <Layout>
    <Seo title="Stritzinger Karriere" />
    {/*<StaticImage*/}
    {/*  src="../images/gatsby-astronaut.png"*/}
    {/*  width={300}*/}
    {/*  quality={95}*/}
    {/*  formats={["AUTO", "WEBP", "AVIF"]}*/}
    {/*  alt="A Gatsby astronaut"*/}
    {/*  style={{ marginBottom: `1.45rem` }}*/}
    {/*/>*/}
  </Layout>
}

export default IndexPage
